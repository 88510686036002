import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { useCurrentIssue } from 'context/IssueContext';

import CoverLayout from 'components/cover/Cover';
import AnniversaryMessage from 'components/cover/AnniversaryMessage';

import { Props as DeansNotesProps } from 'components/cover/DeansNote';

const Cover: React.FC = () => {
  const { issue, features } = useCurrentIssue();

  const { deansNote } = useStaticQuery<{
    deansNote: DeansNotesProps;
  }>(graphql`
    query {
      deansNote: markdownRemark(
        fileAbsolutePath: { regex: "/winter-2019/deans-note/" }
      ) {
        ...DeansNote
      }
    }
  `);

  return (
    <CoverLayout
      issue={issue}
      features={features}
      deansNote={deansNote}
      coverFooter={<AnniversaryMessage />}
    />
  );
};

export default Cover;

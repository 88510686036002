import './AnniversaryMessage.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Core from 'components/core';

const AnniversaryMessage: React.FC = () => {
  const { anniversaryLogo } = useStaticQuery<{
    anniversaryLogo: {
      sizes: {
        fluid: {
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  }>(graphql`
    query AnniversaryMessageQuery {
      anniversaryLogo: file(
        relativePath: { eq: "components/cover/jhu-sais-anniversary-logo.png" }
      ) {
        sizes: childImageSharp {
          fluid(maxWidth: 300) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  return (
    <a
      className="AnniversaryMessage"
      href="https://75.sais-jhu.edu/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="AnniversaryMessage-logo">
        <Img
          fluid={anniversaryLogo.sizes.fluid}
          alt="SAIS 75th anniversary logo"
          className="AnniversaryMessage-logo-image"
        />
      </div>

      <div>
        <Core.SecondaryHeading>
          SAIS&rsquo; Impact Across the World: A Year of 75th Anniversary
          Celebrations
        </Core.SecondaryHeading>

        <p className="AnniversaryMessage-paragraph">
          As the school celebrates its 75th anniversary, we are hosting a
          yearlong series of signature events around the world. Programming will
          touch on issues of great importance in today’s complex global world,
          such as U.S.–China relations, American foreign policy, humanitarian
          crisis and global security, women’s leadership, and renewable energy.
          The yearlong celebrations culminate with the Shape the World Weekend
          taking place in Washington, D.C., October 24–26, 2019.{' '}
          <span className="AnniversaryMessage-link">Learn more</span> about
          these signature events and other 75th anniversary activities.
        </p>
      </div>
    </a>
  );
};

export default AnniversaryMessage;
